:root {
   --pink: #ff83e2;
   --green: #80ff80;
}
input, textarea {
   outline-color: var(--pink);
}
.Container {
    height: 100vh;
    width: 100%;
    overflow-x: hidden;
    scroll-snap-type: y mandatory;
    scroll-behavior: smooth;
    overflow-y: auto;
    color: white;
    /* background-image: url("./public/img/blackbg.jpg"); */
    background-color: black;
    background-size: cover;
    scrollbar-width: none;
    /* Remove scrollbar */
    &::-webkit-scrollbar {
    display: none;
}
}



.Events-section {
   height: 100vh;
   scroll-snap-align: center;
   display: flex;
   justify-content: center;
   position: relative;
}
.Home-section {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    scroll-snap-align: center;
    

 }

 .Works-section {
    height: 100vh;
    scroll-snap-align: center;
    display: flex;
    justify-content: center;

 }

 .Contact-section {
    height: 100vh;
    scroll-snap-align: center;
    position: relative;
 }

.greenBackground {
   position: absolute;
   top: 0;
   z-index: 0;
   overflow: visible;
   
   @media (max-width: 1400px) {
      width: 800px; 
     top:0;
     left: 0%;
   }
   @media ( max-width: 1399px)
   {
      top: 0;
      left: -4vw;
   }
   



}


.lotus-section {
   z-index: 1;


}


/* team page */
.item1 {
   grid-column: -2;

   @media (max-width: 579px)
   {
      grid-column: auto;
   }
}
.item2 {
   grid-column: 2;
   
   @media (max-width: 579px) {
      grid-column: auto;
   }
}
.card-image {
   width: 100%;
   height: 100%;
   object-fit: cover;
   overflow: hidden;
   border-radius: 13%;

}
.card-name {
   writing-mode: vertical-lr;
   font-size:xx-large;
   font-weight: bold;
   margin: auto;
   color: var(--pink);
   white-space: nowrap;

   @media (max-width: 579px) {
      font-size: 20px;
   }
   
}
.card-position {
   width: 100%;
   height: 100%;
   margin: auto;
   padding-top: 5px;
   font-size:xx-large;
   font-weight: bold;
   text-align: center;
   color: var(--green);
  
      @media (max-width: 579px) {
      font-size: 20px;;
   }
}


/* Top wave */

.wave-container {  
   height: 60vh;
   width: 100%;
   background: linear-gradient(to bottom, #80ff80 75%, white 99%);
   position: absolute;
   bottom: 15%;
   left: 0;
 }
 
 .wave-container::before {   
   content: "";
   width: 100%;
   height: 130px;
   position: absolute;
   bottom: 0;
   right: 0;
   background-size: auto;
   background-repeat: repeat no-repeat;
   background-position: 20vw bottom;
   background-image: url("data:image/svg+xml;utf8,<svg viewBox='0 0 1200  83' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M0 60L50 57C100 53 200 46 300 31C400 16 500 -6 600 1C700 8 800 46 900 60C1000 75 1100 68 1150 64L1200 60V83H1150C1100 83 1000 83 900 83C800 83 700 83 600 83C500 83 400 83 300 83C200 83 100 83 50 83H0V60Z' fill='%23000000'/></svg>");
 }


 
 @media(max-width:850px) {
   .wave-container::before {    
     height: 70.5px
   }  
 }


 @media(max-width:850px) {
   .wave-container::before {    
     height: 65px
   }  
 }


 .snap-inline {
   scroll-snap-type: inline mandatory;
 }

 .snap-inline > * {
   scroll-snap-align: start;
 }